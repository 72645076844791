<template>
    <div class="task_block">
        <div class="screen_title">TASK</div>
        <div class="screen_sub_title">Cat Hunter, welcome admin!</div>
        <div class="task_create_block">
            <div class="task_row">
                <p>Title</p>
                <input type="text" v-model="task.title" placeholder="Enter title...">
            </div>
            <div class="task_row">
                <p>Link</p>
                <input type="text" v-model="task.link" placeholder="Enter link...">
            </div>
            <div class="task_row">
                <p>Bonus</p>
                <input type="number" v-model="task.bonus" placeholder="Enter bonus...">
            </div>
            <div class="mt_10">
                <button class="button_default" @click="onCreateTask()">Up Task</button>
            </div>
        </div>
        <hr>
        <p class="task_title">Task list</p>
        <div class="task_list_area">
            <div :class="['task_item_area', task.status === 1 ? 'active' : 'locked']" v-for="task in tasks" :key="task"
                @click="onUpdateTask(task.taskId, task.status === 1 ? 0 : 1)">
                <div>{{ task.title }}</div>
                <div>{{ task.link }}</div>
                <div>Bonus: {{ task.bonus }} CATCH</div>
                <div>Clicked: {{ task.clicked }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http";
export default {
    /* eslint-disable */
    name: "task",
    data() {
        return {
            task: {
                title: "",
                link: "",
                bonus: 0
            },
            tasks: []
        }
    },
    methods: {
        onUpdateTask(taskId, status) {
            http.post("/admin/task/update", {
                id: taskId,
                status: status
            },
                { headers: { Authorization: localStorage.getItem("hashing_hashing") } }
            ).then(res => {
                if (res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Updated!",
                        type: "success"
                    });

                    this.getTask();
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Error!",
                        type: "error"
                    });
                }
            });
        },
        onCreateTask() {
            if (!this.task.title || !this.task.link || !this.task.bonus) {
                this.$notify({
                    title: "MESSAGE",
                    text: "Please Fill Information!",
                    type: "error"
                });
                return;
            }

            http.post("/admin/task/create", {
                ...this.task
            },
                { headers: { Authorization: localStorage.getItem("hashing_hashing") } }
            ).then(res => {
                if (res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Created!",
                        type: "success"
                    });

                    this.getTask();
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Error!",
                        type: "error"
                    });
                }
            });
        },
        getTask() {
            http.get("/admin/tasks", { headers: { Authorization: localStorage.getItem("hashing_hashing") } }).then(res => {
                if (res.data.status === 200) {
                    this.tasks = res.data.data;
                }
            });
        }
    },
    mounted() {
        this.getTask();
    }
}
</script>