<template>
    <div class="login_block_hashing">
        <div class="screen_title">LOGIN</div>
        <div class="screen_sub_title">Hashing & Hashing!</div>
        <div class="login_block">
            <div class="login_row">
                <input type="text" v-model="hashing" placeholder="Enter Hashing & Hashing">
            </div>
            <div class="login_action">
                <button @click="hashingClick()">Login</button>
            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http";
export default {
    /* eslint-disable */
    name: "login",
    data() {
        return {
            hashing: ""
        }
    },  
    methods: {
        async hashingClick() {
            return http.post('/admin/login', {
                hashing: this.hashing
            }).then(res => {
                console.log(res);
                if(res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "success"
                    });

                    localStorage.setItem("hashing_hashing", res.data.hashing);
                    this.$router.push({ name: "Home" });
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "error"
                    });
                }
            });
        }
    }
}
</script>