<template>
    <div class="dashboard_block">
        <div class="screen_title">DASHBOARD</div>
        <div class="screen_sub_title">Cat Hunter, welcome admin!</div>
        <div class="main_menu">
            <a href="/trading-push">Trading Push</a>
            <a href="/claim-histories">Claim Histories</a>
        </div>
        <div class="dashboard_analytics">
            <div class="dashboard_item">
                <div class="dashboard_item_text">
                    Hunter
                </div>
                <div class="dashboard_item_number">
                    {{ users }}
                </div>
            </div>
            <div class="dashboard_item" style="margin-left: 10px;">
                <div class="dashboard_item_text">
                    Transactions
                </div>
                <div class="dashboard_item_number">
                    {{ transactions }}
                </div>
            </div>
        </div>
        <div class="chart_block">
            <div class="chart_item">
                <LineChart :chartData="transactionChart" :options="options"/>
            </div>
            <div class="chart_item">
                <LineChart :chartData="catchChart" :options="catchOptions"/>
            </div>
        </div>
    </div>
</template>
<script>
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import http from "@/http";

Chart.register(...registerables);
export default {
    /* eslint-disable */
    name: "home",
    components: {
        LineChart
    },
    methods: {
        getReports() {
            http.get("/admin/report", {
                headers: {
                    Authorization: localStorage.getItem("hashing_hashing")
                }
            }).then(res => {
                console.log(res);
                if(res.data.status === 200) {
                    let reportLine = String(res.data.data).split("\n");
                    let transactions = [];
                    let labels = [];
                    let catchs = [];

                    if(reportLine.length - 1 < 120) {
                        let pushMore = 120 - reportLine.length - 1;
                        for(let index = 0; index < pushMore; index++) {
                            labels.push(0);
                            transactions.push(0);
                            catchs.push(0);
                        }
                    }

                    for(let report of reportLine) {
                        if(report) {
                            let reportDetail = String(report).split("|");
                            transactions.push(reportDetail[0]);
                            labels.push(reportDetail[2]);
                            catchs.push(reportDetail[1]);
                        }
                    }

                    if(labels.length > 120) {
                        labels = labels.splice(labels.length - 120 - 1, labels.length - 1);
                        transactions = transactions.splice(transactions.length - 120 - 1, transactions.length - 1);
                        catchs = catchs.splice(catchs.length - 120 - 1, catchs.length - 1);
                    }

                    this.transactionChart.datasets[0].data = transactions;
                    this.transactionChart.labels = labels;

                    this.catchChart.datasets[0].data = catchs;
                    this.catchChart.labels = labels;
                }
            });
        },
        getDashboard() {
            http.get("admin/dasboard", {
                headers: {
                    Authorization: localStorage.getItem("hashing_hashing")
                }
            }).then(res => {
                if(res.data.status === 401) {
                    return this.$router.push({ name: "Login" });
                }

                if(res.data.status === 200) {
                    this.transactions = res.data.data.tranCounter;
                    this.users = res.data.data.userCounter;
                }
            });
        }
    },  
    mounted() {
        this.getDashboard();
        this.getReports();

        if(this.chartRenderThead) {
            clearInterval(this.chartRenderThead);
        }

        this.chartRenderThead = setInterval(() => {
            this.getReports();
        }, 30000);
    },
    data() {
        return {
            chartRenderThead: null,
            transactions: 0,
            users: 0,
            transactionChart: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: false,
                        tension: 0,
                        borderWidth: 1,
                        borderColor: "#44a4fc",
                    },
                ]
            },
            catchChart: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        fill: false,
                        tension: 0,
                        borderWidth: 1,
                        borderColor: "#44a4fc",
                    },
                ]
            },
            options: {
                elements: {
                    point: {
                        radius: 0
                    }
                },
                plugins: {
                    title: {
                        text: "Transactions",
                        display: true
                    },
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        display: false
                    }
                }
            },
            catchOptions: {
                elements: {
                    point: {
                        radius: 0
                    }
                },
                plugins: {
                    title: {
                        text: "CATH",
                        display: true
                    },
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        display: false
                    }
                }
            }
        }
    }
}
</script>