<template>
    <div>
        <div class="screen_title">User</div>
        <div class="screen_sub_title">User Lists!</div>
        <div class="user_lists">
            <div class="user_item" v-for="user in users" :key="user">
                <p>{{ user.username }}</p>
                <div>Balance: {{ numberWithCommas(user.balance) }}</div>
                <div>Transaction: {{ numberWithCommas(user.totalTransactions) }}</div>
                <div>Spin: {{ numberWithCommas(user.totalSpins) }}</div>
                <div>Refs: {{ numberWithCommas(user.totalRefs) }}</div>
            </div>
        </div>
        <div>
            <v-pagination v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF"
                @update:modelValue="changePage" />
        </div>
    </div>
</template>
<script>
import http from "@/http";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    /* eslint-disable */
    name: "User",
    components: {
        VPagination
    },
    data() {
        return {
            page: 1,
            users: [],
            totals: 8,
            pages: 1
        }
    },
    methods: {
        changePage() {
            this.getUsers();
        },
        getUsers() {
            http.get("/admin/users", { headers: { Authorization: localStorage.getItem("hashing_hashing") }, params: { page: this.page } }).then(res => {
                if (res.data.status === 200) {
                    this.users = res.data.data;
                    this.pages = Math.ceil(res.data.totals / 50);
                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    mounted() {
        this.getUsers();
    }
}
</script>