<template>
    <div>
        <div class="screen_title">Spin</div>
        <div class="screen_sub_title">Spin Transactions!</div>
        <div class="user_lists">
            <div class="user_item" v-for="user in users" :key="user">
                <p>{{ user.username.username }}</p>
                <div>TON: {{ user.result }}</div>
                <div v-if="user.type === 'spin'" style="color: #ffb648;">==> SPIN</div>
                <div v-else  style="color: red;">==> CLAIMED</div>
                <div>{{ formatDate(user.date) }}</div>
            </div>
        </div>
        <div>
            <v-pagination v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF"
                @update:modelValue="changePage" />
        </div>
    </div>
</template>
<script>
import http from "@/http";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import moment from "moment";
export default {
    /* eslint-disable */
    name: "Spin",
    components: {
        VPagination
    },
    data() {
        return {
            page: 1,
            users: [],
            totals: 8,
            pages: 1
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
        changePage() {
            this.getUsers();
        },
        getUsers() {
            http.get("/admin/spins", { headers: { Authorization: localStorage.getItem("hashing_hashing") }, params: { page: this.page } }).then(res => {
                if (res.data.status === 200) {
                    this.users = res.data.data;
                    this.pages = Math.ceil(res.data.totals / 50);
                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    mounted() {
        this.getUsers();
    }
}
</script>