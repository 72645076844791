<template>
  <div style="padding-bottom: 80px;">
    <router-view></router-view>
    <notifications />
    <div class="footer" v-if="checkHideLayout()">
      <div :class="['footer_item', checkActiveMenu('Home') ? 'active' : '']">
        <a href="/">
          <div class="footer_icon"><rocket-launch-icon class="icon" /></div>
          <div class="footer_text">Dashboard</div>
        </a>
      </div>
      <div :class="['footer_item', checkActiveMenu('Task') ? 'active' : '']">
        <a href="/task">
          <div class="footer_icon"><beaker-icon class="icon" /></div>
          <div class="footer_text">Task</div>
        </a>
      </div>
      <div :class="['footer_item', checkActiveMenu('User') ? 'active' : '']">
        <a href="/user">
          <div class="footer_icon"><users-icon class="icon" /></div>
          <div class="footer_text">User</div>
        </a>
      </div>
      <div :class="['footer_item', checkActiveMenu('Spin') ? 'active' : '']">
        <a href="/spin">
          <div class="footer_icon"><credit-card-icon class="icon" /></div>
          <div class="footer_text">Claim</div>
        </a>
      </div>
      <div :class="['footer_item', checkActiveMenu('PreSale') ? 'active' : '']">
        <a href="/pre-sale">
          <div class="footer_icon"><currency-dollar-icon class="icon" /></div>
          <div class="footer_text">Pre-Sale</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { BeakerIcon, 
  RocketLaunchIcon, 
  CreditCardIcon,
  UsersIcon,
  CurrencyDollarIcon
} from '@heroicons/vue/24/solid';
export default {
  name: 'App',
  components: {
    BeakerIcon,
    RocketLaunchIcon,
    CreditCardIcon,
    UsersIcon,
    CurrencyDollarIcon
  },
  methods: {
    checkActiveMenu(name) {
      if (this.$route.name && this.$route.name === name) {
        return true;
      } else {
        return false;
      }
    },
    checkHideLayout() {
      if(this.$route.name === 'Login') {
        return false;
      }
      return true;
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/style.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
