<template>
    <div>
        <div class="screen_title">PreSale</div>
        <div class="screen_sub_title">PreSale Congfiguration!</div>
        <div class="task_create_block">
            <div class="task_row">
                <p>Time End</p>
                <input type="date" v-model="congfig.timeLeft" placeholder="Enter time...">
            </div>
            <div class="task_row">
                <p>Quantity Sold</p>
                <input type="number" v-model="congfig.quantitySold" placeholder="Enter number...">
            </div>
            
            <div class="mt_10">
                <button class="button_default" @click="saveConfig()">Update</button>
            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http";
import moment from "moment";
export default {
    /* eslint-disable */
    name: "PreSale",
    data() {
        return {
            congfig: {
                timeLeft: "",
                quantitySold: 0
            }
        }
    },
    methods: {
        getPreSaleConfig() {
            http.get("/admin/setting/data", { headers: { Authorization: localStorage.getItem("hashing_hashing") }, params: { page: this.page } }).then(res => {
                if(res.status === 200) {
                    const data = res.data;
                    this.congfig = {
                        ...data,
                        timeLeft: moment(data.timeLeft).format("yyyy-MM-DD")
                    }
                }
            });
        },
        saveConfig() {
            http.post("/admin/setting/save", {
                timeLeft: moment(this.congfig.timeLeft).valueOf(),
                quantitySold: this.congfig.quantitySold
            },
                { headers: { Authorization: localStorage.getItem("hashing_hashing") } }
            ).then(res => {
                if(res.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Updated!",
                        type: "success"
                    });
                }
            });
        }
    },
    mounted() {
        this.getPreSaleConfig();
    }
}
</script>