<template>
    <div class="trading_block">
        <div class="screen_title">Trading</div>
        <div class="screen_sub_title">Push Trading!</div>
        <div class="trading_form">
            <div class="form_items">
                <label for="trading_type">Type</label>
                <div class="form_input">
                    <select v-model="form.type" id="trading_type">
                        <option value="buy">Buy</option>
                        <option value="sell">Sell</option>
                    </select>
                </div>
            </div>
            <div class="form_items">
                <label for="trading_type">Amount</label>
                <div class="form_input">
                    <input v-model="form.amount" type="number">
                </div>
            </div>
            <div class="form_items">
                <span class="form_text">Current Price: {{ lastPrice }}</span>
            </div>
            <div class="form_items" style="text-align: center;">
                <button class="button_default" @click="pushTrading">PUSH</button>
            </div>
            <hr style="margin-top: 20px;">
            <p class="task_title" style="margin-top: 20px;">Trading list</p>
            <div class="trading_list">
                <div class="locked" v-for="trading in tradings" :key="trading">
                    <div>{{ trading.type }}</div>
                    <div>{{ trading.amount }}</div>
                    <div>Time: {{ humanTime(trading.timestamp)}} </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http";
import moment from "moment";
export default {
    /* eslint-disable */
    name: "Trading",
    data() {
        return {
            lastPrice: 0,
            form: {
                type: "buy",
                amount: 0,
                price: 0
            },
            tradings: []
        }
    },
    methods: {
        humanTime(timestamp) {
            return moment.duration(moment().diff(moment(timestamp))).humanize();
        },
        async getPrice() {
            return http.get("/trading/last-price").then(res => {
                if (res.data.status === 200) {
                    if (res.data.price == 0) {
                        this.lastPrice = 0.00006;
                    } else {
                        this.lastPrice = parseFloat(res.data.price).toFixed(4);
                    }
                }
            });
        },
        async getLastTrading() {
            return http.get("/admin/trading/last", { headers: { Authorization: localStorage.getItem("hashing_hashing") } }).then(res => {
                if (res.data.status === 200) {
                    this.tradings = res.data.data;
                    console.log(this.tradings);
                }
            });
        },
        async pushTrading() {
            this.form.price = this.lastPrice;
            return http.post("/admin/trading/push", {
                ...this.form
            }, { headers: { Authorization: localStorage.getItem("hashing_hashing") } }).then(res => {
                this.getLastTrading();
                if (res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Error!",
                        type: "error"
                    });
                }
            });
        }
    },
    mounted() {
        this.getPrice();
        this.getLastTrading();
    }
}
</script>